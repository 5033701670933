<template>
  <ContentField>
    <p class="fw-bold">Changer de mot de passed</p>
    <div class="row justify-content-md-center">
      <div class="col">
        <form @submit.prevent="change_password">
          <div class="mb-3">
            <label for="username" class="form-label">Ancien mot de passe</label>
            <input v-model="username" type="password" class="form-control" id="username" placeholder="Saisir l'ancien mot de passe">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Nouveau mot de passe</label>
            <input v-model="password" type="password" class="form-control" id="password" placeholder="Saisir le nouveau mot de passe">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Confirmer le mot de passe </label>
            <input v-model="confirmed_password" type="password" class="form-control" id="confirmed_password" placeholder="Confirmer le nouveau mot de passe">
          </div>
          <div class="error-message">{{ error_message }}</div>
          <button type="submit" class="btn btn-primary">Changer de mot de passe </button>
        </form>
      </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import {ref} from "vue";
import $ from 'jquery';
import {useStore} from "vuex";

export default {
  name: "ChangePasswordView",
  components: {
    ContentField
  },
  setup() {
    const store = useStore();

    let username = ref('');
    let password = ref('');
    let confirmed_password = ref('');
    let error_message = ref('');

    let change_password = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/user/account/changepassword/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          raw_password: username.value,
          new_password: password.value,
          confirmed_password: confirmed_password.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message.value = "Mot de passe modifié avec succès ";
          } else {
            error_message.value = resp.error_message;
          }
        }
      })
    }

    return {
      username,
      password,
      confirmed_password,
      error_message,

      change_password,
    }
  }
}
</script>

<style scoped>
button {
  width: 100%;
}

div.error-message {
  color: red;
}
</style>