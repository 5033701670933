<template>
  <ContentField>
    <p class="fw-bold">Information personnelle</p>
    <div class="row justify-content-md-center">
      <div class="col">
        <form @submit.prevent="change_info">
          <div class="mb-3">
            <label class="form-label">Nom</label>
            <input v-model="name" type="text" class="form-control">
          </div>
          <div class="mb-3">
            <label class="form-label">Numéro de téléphone</label>
            <input v-model="username" class="form-control">
          </div>
          <div class="mb-3">
            <label class="form-label">Email</label>
            <input v-model="email" class="form-control">
          </div>
          <div class="mb-3">
            <label class="form-label">Date de naissance</label>
            <VueDatePicker v-model='birthday' type="date" :format="format" id="birthday" placeholder="Saisir votre nom "></VueDatePicker>
          </div>
          <div class="error-message">{{ error_message }}</div>
          <button type="submit" class="btn btn-primary">SAUVEGARDER</button>
        </form>
      </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import VueDatePicker from '@vuepic/vue-datepicker';
import {useStore} from "vuex";
import {ref} from "vue";
import $ from 'jquery';

export default {
  name: "UserInfoView",
  components: {
    ContentField,
    VueDatePicker
  },
  setup() {
    const format = (birthday) => {
      const day = birthday.getDate();
      const month = birthday.getMonth() + 1;
      const year = birthday.getFullYear();

      return `${day}/${month}/${year}`;
    }
    let name = ref('');
    let username = ref('');
    let email = ref('');
    let birthday = ref(new Date());
    let error_message = ref('');

    const store = useStore();

    let refresh_content = () => {
      name.value = store.state.user.name;
      username.value = store.state.user.username;
      email.value = store.state.user.email;
      birthday.value = new Date(store.state.user.birthday);
    }

    refresh_content();

    let change_info = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/user/account/changeinfo/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          name: name.value,
          username: username.value,
          email: email.value,
          birthday: birthday.value.getTime(),
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message.value = "Sauvegarder avec succès ";
          }
        }
      })
    }

    return {
      name,
      username,
      email,
      birthday,
      error_message,

      format,

      change_info
    }
  }
}
</script>

<style scoped>
button {
  width: 100%;
}

div.error-message {
  color: red;
}
</style>