import { createRouter, createWebHistory } from "vue-router";
import UserInfoView from "@/views/user/UserInfoView";
import ChangePasswordView from "@/views/user/ChangePasswordView";
import MyRecordView from "@/views/user/MyRecordView";
import HomeView from "@/views/home/HomeView";
import RegisterView from "@/views/user/RegisterView";
import LoginView from "@/views/user/LoginView";
import store from '@/store'
import ChangeVariableView from "@/views/user/ChangeVariableView";
import DeleteRecordView from "@/views/user/AllRecordView";
import ResetPasswordView from "@/views/user/ResetPasswordView";
import UserallView from "@/views/user/UserallView";
import UploadBarcodeView from "@/views/user/UploadBarcodeView";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            requestAuth: false,
            showChat: true
        }
    },
    {
        path: '/userinfo/',
        name: 'user_info',
        component: UserInfoView,
        meta: {
            requestAuth: true,
            showChat: true
        }
    },
    {
        path: '/changepassword/',
        name: 'change_password',
        component: ChangePasswordView,
        meta: {
            requestAuth: true,
            showChat: true
        }
    },
    {
        path: '/myrecord/',
        name: 'my_record',
        component: MyRecordView,
        meta: {
            requestAuth: false,
            showChat: true
        }
    },
    {
        path: '/register/',
        name: 'register',
        component: RegisterView,
        meta: {
            requestAuth: false,
            showChat: false
        }
    },
    {
        path: '/login/',
        name: 'login',
        component: LoginView,
        meta: {
            requestAuth: false,
            showChat: false
        }
    },
    {
        path: '/changevariable/',
        name: 'change_variable',
        component: ChangeVariableView,
        meta: {
            requestAuth: true,
            showChat: true
        }
    },
    {
        path: '/reset_password/',
        name: 'reset_password',
        component: ResetPasswordView,
        meta: {
            requestAuth: true,
            showChat: true
        }
    },
    {
        path: '/recordall/',
        name: 'record_all',
        component: DeleteRecordView,
        meta: {
            requestAuth: true,
            showChat: true
        }
    },
    {
        path: '/userall/',
        name: 'user_all',
        component: UserallView,
        meta: {
            requestAuth: true,
            showChat: true
        }
    },
    {
        path: '/barcode/',
        name: 'upload_barcode',
        component: UploadBarcodeView,
        meta: {
            requestAuth: true,
            showChat: true
        }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requestAuth && !store.state.user.is_login) {
        next({ name: 'home' });
    } else {
        next();
    }
})

export default router