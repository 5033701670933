<template>
  <NarBar></NarBar>
  <router-view></router-view>
</template>

<script>
import NarBar from "@/components/NarBar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { useStore } from 'vuex';

export default {
  name: 'App',
  components: {
    NarBar
  },
  setup() {
    const store = useStore();
    store.dispatch("getvar");
  }
}
</script>

<style>

</style>
