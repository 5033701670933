<template>
  <ContentField>
    <div class="row justify-content-md-center">
      <div class="col">
        <form @submit.prevent="reset">
          <div class="mb-3">
            <label for="username" class="form-label">Numéro de téléphone </label>
            <input v-model="username" type="text" class="form-control" id="username" placeholder="Saisir le numéro de téléphone">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Mot de passe </label>
            <input v-model="password" type="password" class="form-control" id="password" placeholder="Saisir le mot de passe">
          </div>
          <div class="error-message">{{ error_message }}</div>
          <button type="submit" class="btn btn-primary">Reset password</button>
        </form>
      </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import {ref} from "vue";
import $ from 'jquery';
import {useStore} from "vuex";

export default {
  name: "ResetPasswordView",
  components: {ContentField},
  setup() {
    const store = useStore();

    let username = ref('');
    let password = ref('');
    let error_message = ref('');

    let reset = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/user/account/resetpassword/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          username: username.value,
          password: password.value,
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message.value = "succès";
          } else {
            error_message.value = resp.error_message;
          }
        }
      })
    }

    return {
      username,
      password,
      error_message,

      reset,
    }
  }
}
</script>

<style scoped>
button {
  width: 100%;
}

div.error-message {
  color: red;
}
</style>