<template>
  <ContentField>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Recherche avec numéro de téléphone </h5>
        <el-input v-model="phone_number" placeholder="Numéro de téléphone"></el-input>
        <el-button type="primary" style="margin-top: 5px" @click="retrieve_by_phone">Examiner</el-button>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">id</th>
        <th scope="col">phone</th>
        <th scope="col">name</th>
        <th scope="col">email</th>
        <th scope="col">birthday</th>
        <th scope="col">alipay</th>
        <th scope="col">wechat</th>
        <th scope="col">operator</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in data" :key="item.id">
        <td>{{ index }}</td>
        <td>{{ item.username }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.birthday }}</td>
        <td>
          <el-button type="primary" @click="showDialog(item.alipaycode)">alipay</el-button>
        </td>
        <td>
          <el-button type="primary" @click="showDialog(item.wechatcode)">wechat</el-button>
        </td>
        <td v-if="item.id !== 1">
          <button class="btn-primary btn" @click="delete_user(item.id)">delete</button>
        </td>
      </tr>
      </tbody>
    </table>
    <div><div class="error-message">{{ error_message }}</div></div>
  </ContentField>
  <el-dialog
      v-model="dialogVisible"
      title="photo"
      width="30%">
    <el-image :src="image_url"></el-image>
  </el-dialog>
</template>

<script>
import ContentField from "@/components/ContentField";
import {useStore} from "vuex";
import {ref} from "vue";
import $ from 'jquery';

export default {
  name: "UserallView",
  components: {ContentField},
  setup() {
    const store = useStore();

    let data = ref();
    let error_message = ref('');

    let dialogVisible = ref(false);
    let image_url = ref();

    let showDialog = (link) => {
      dialogVisible.value = true;
      image_url.value = link;
    }

    let get_all = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/user/account/getall/',
        type: 'get',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          data.value = resp;
        }
      })
    }

    let refresh_content = () => {
      get_all();
    }
    get_all();

    let delete_user = (id) => {
      $.ajax({
        url: 'https://expresspaybn.com/api/user/account/delete/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          id: id
        },
        success(resp) {
          if (resp.error_message === "success") {
            refresh_content();
          } else {
            error_message.value = resp.error_message;
          }
        }
      })
    }

    let phone_number = ref();
    let retrieve_by_phone = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/user/account/getbyphone/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          username: phone_number.value
        },
        success(resp) {
          data.value = resp;
        }
      })
    }

    return {
      data,
      error_message,

      showDialog,
      dialogVisible,
      image_url,

      delete_user,

      phone_number,
      retrieve_by_phone
    }
  }
}
</script>

<style scoped>
div.error-message {
  color: red;
}
</style>