<template>
  <ContentField>
    <div class="mb">
      <label class="form-label">Upload Alipay QR Code</label>
      <el-upload
          v-model:file-list="fileList1"
          action="https://expresspaybn.com/api/alipaycode/upload/"
          :headers="headers"
          list-type="picture-card"
          :on-change="uploadAlipaycode"
          :on-preview="handlePictureCardPreview"
          :on-remove="handle_remove"
          :limit="1"
          :on-exceed="maxFileLimit"
          :before-upload="beforeUpload"
          name="image"
      >
        <el-icon><Plus /></el-icon>
      </el-upload>
      <el-dialog v-model="dialogVisible">
        <img w-full :src="dialogImageUrl" alt="Preview Image" />
      </el-dialog>
      <div class="error-message">{{ image_error_message }}</div>
    </div>

    <div class="mb">
      <label class="form-label">Upload WeChat QR Code</label>
      <el-upload
          v-model:file-list="fileList2"
          action="https://expresspaybn.com/api/wechatcode/upload/"
          :headers="headers"
          list-type="picture-card"
          :on-change="uploadWechatcode"
          :on-preview="handlePictureCardPreview"
          :on-remove="handle_remove"
          :limit="1"
          :on-exceed="maxFileLimit"
          :before-upload="beforeUpload"
          name="image"
      >
        <el-icon><Plus /></el-icon>
      </el-upload>
      <el-dialog v-model="dialogVisible">
        <img w-full :src="dialogImageUrl" alt="Preview Image" />
      </el-dialog>
      <div class="error-message">{{ image_error_message }}</div>
    </div>
    <el-divider />
    <div class="demo-image__error">
      <div class="block">
        <span class="demonstration">Alipay</span>
        <el-image :src="alipaycode">
        </el-image>
      </div>
      <div class="block">
        <span class="demonstration">WeChat</span>
        <el-image :src="wechatcode">
        </el-image>
      </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import {useStore} from "vuex";
import {ref} from "vue";
import $ from 'jquery';

export default {
  name: "UploadBarcodeView",
  components: {
    ContentField,
  },
  setup() {
    const store = useStore();

    let alipaycode = ref('');
    let wechatcode = ref('');
    let refresh_content = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/alipaycode/get/',
        type: 'get',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          alipaycode.value = resp.result;
        }
      });
      $.ajax({
        url: 'https://expresspaybn.com/api/wechatcode/get/',
        type: 'get',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          wechatcode.value = resp.result;
          // console.log(wechatcode.value)
        }
      })
    }
    refresh_content();

    let headers = ref({
      Authorization: "Bearer " + store.state.user.token,
    });

    let fileList1 = ref([]);
    let fileList2 = ref([]);
    let file_url = [];
    let dialogImageUrl = ref('');
    let dialogVisible = ref(false);
    let image_error_message = ref("");

    let uploadAlipaycode = () => {
      file_url = [];
      for (let i = 0; i < fileList1.value.length; i++) {
        file_url.push(fileList1.value[i].response.image);
        image_error_message.value = "";
      }
      refresh_content()
    }

    let uploadWechatcode = () => {
      file_url = [];
      for (let i = 0; i < fileList2.value.length; i++) {
        file_url.push(fileList2.value[i].response.image);
        image_error_message.value = "";
      }
      refresh_content()
    }

    let handlePictureCardPreview = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    }

    let handle_remove = (uploadFiles, uploadFile) => {
      console.log(uploadFile)
      file_url = [];
      for (let i = 0; i < uploadFiles.length; i++) {
        file_url.push(uploadFiles[i].response.image);
      }
    }

    let beforeUpload = (file) => {
      // 获取上传文件的大小（以字节为单位）
      let fileSize = file.size;

      // 定义允许的最大文件大小（以字节为单位）
      let maxSize = 5 * 1024 * 1024; // 5MB
      console.log(fileSize)

      // 检查文件大小是否超过允许的最大值
      if (fileSize > maxSize) {
        // 文件大小超过限制，拒绝上传
        //this.$message.error('文件大小超过限制（最大5MB）');
        return false;
      }

      // 文件大小符合要求，允许上传
      return true;
    }

    let maxFileLimit = () => {
      image_error_message.value = "Vous ne pouvez pas télécharger plus de 3 photos "
    }

    // let clear_image = () => {
    //   image_error_message.value = "";
    //   fileList.value = [];
    //   file_url = [];
    //   dialogVisible.value = false;
    //   dialogImageUrl.value = "";
    // }

    return {
      wechatcode,
      alipaycode,

      image_error_message,
      maxFileLimit,

      headers,
      fileList1,
      fileList2,
      handlePictureCardPreview,
      dialogVisible,
      dialogImageUrl,
      uploadAlipaycode,
      uploadWechatcode,
      handle_remove,
      beforeUpload
    }
  }
}
</script>

<style scoped>
.demo-image__error .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 49%;
  box-sizing: border-box;
  vertical-align: top;
}
.demo-image__error .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}
.demo-image__error .el-image {
  padding: 0 5px;
  max-width: 300px;
  max-height: 200px;
  width: 100%;
  height: 200px;
}

.demo-image__error .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
</style>