<template>
  <ContentField>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Recherche avec numéro de téléphone </h5>
        <el-input v-model="phone_number1" placeholder="Numéro de téléphone"></el-input>
        <el-button type="primary" style="margin-top: 5px" @click="retrieve_by_phone1">Examiner</el-button>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">id</th>
        <th scope="col">type</th>
        <th scope="col">envoyer</th>
        <th scope="col">frais</th>
        <th scope="col">inclus</th>
        <th scope="col">unit</th>
        <th scope="col">recevoir</th>
        <th scope="col">unit</th>
        <th scope="col">date</th>
        <th scope="col">name</th>
        <th scope="col">phone</th>
        <th scope="col">Photo </th>
        <th scope="col">opertor</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in data1" :key="item.id">
        <td>{{ index }}</td>
        <td v-if="item.type === 1">GABON/SENEGAL VERS CHINE</td>
        <td v-else-if="item.type === 2">CHINE VERS GABON/SENEGAL</td>
        <td v-else-if="item.type === 3">GABON VERS SENEGAL</td>
        <td v-else-if="item.type === 5">ENVOYER VIA PAYPAL</td>
        <td v-else-if="item.type === 6">RECEVOIR VIA PAYPAL</td>
        <td v-else>GABON/SENEGAL VERS CHINE</td>
        <td>{{ item.envoyer }}</td>
        <td>{{ item.frais }}</td>
        <td>{{ item.inclus }}</td>
        <td>{{ get_money_unit_name(item.moneyUnitOne) }}</td>
        <td>{{ item.recevoir }}</td>
        <td>{{ get_money_unit_name(item.moneyUnitTwo) }}</td>
        <td>{{ item.date }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.username }}</td>
        <td>
          <el-button type="primary" @click="showDialog(item.image1)">Photo1 &nbsp;</el-button>
          <el-button type="primary" @click="showDialog(item.image2)">Photo2 &nbsp;</el-button>
          <el-button type="primary" @click="showDialog(item.image3)">Photo3</el-button>
        </td>
        <td>
          <button class="btn-primary btn" @click="delete_row1(item)">delete</button>
        </td>
      </tr>
      </tbody>
    </table>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Recherche avec numéro de téléphone </h5>
        <el-input v-model="phone_number2" placeholder="Numéro de téléphone"></el-input>
        <el-button type="primary" style="margin-top: 5px" @click="retrieve_by_phone2">Examiner</el-button>
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">id</th>
        <th scope="col">type</th>
        <th scope="col">montant</th>
        <th scope="col">rate</th>
        <th scope="col">rembourser</th>
        <th scope="col">unit</th>
        <th scope="col">name</th>
        <th scope="col">phone</th>
        <th scope="col">passport</th>
        <th scope="col">address</th>
        <th scope="col">date</th>
        <th scope="col">Photo </th>
        <th scope="col">opertor</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in data2" :key="item.id">
        <td>{{ index }}</td>
        <td>PRÊT D'ARGENT</td>
        <td>{{ item.montant }}</td>
        <td>{{ item.rate }} % </td>
        <td>{{ item.rembourser }}</td>
        <td>{{ get_money_unit_name(item.moneyUnit) }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.username }}</td>
        <td>{{ item.passport }}</td>
        <td>{{ item.address }}</td>
        <td>{{ item.date }}</td>
        <td>
          <el-button type="primary" @click="showDialog(item.image1)">Photo1 &nbsp;</el-button>
          <el-button type="primary" @click="showDialog(item.image2)">Photo2 &nbsp;</el-button>
          <el-button type="primary" @click="showDialog(item.image3)">Photo3</el-button>
        </td>
        <td>
          <button class="btn-primary btn" @click="delete_row2(item)">delete</button>
        </td>

      </tr>
      </tbody>
    </table>

  </ContentField>
  <el-dialog
      v-model="dialogVisible"
      title="photo"
      width="30%">
    <el-image :src="image_url"></el-image>
  </el-dialog>
</template>

<script>
import ContentField from "@/components/ContentField";
import {useStore} from "vuex";
import $ from 'jquery';
import {ref} from "vue";

export default {
  name: "AllRecordView",
  components: {ContentField},
  setup() {
    const store = useStore();

    let data1 = ref();
    let data2 = ref();
    let error_message = ref('');

    let dialogVisible = ref(false);
    let image_url = ref();

    let showDialog = (link) => {
      dialogVisible.value = true;
      image_url.value = link;
    }

    let getrecord1 = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record1/getall/',
        type: 'get',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          data1.value = resp;
        }
      })
    }
    let getrecord2 = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record2/getall/',
        type: 'get',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          data2.value = resp;
        }
      })
    }

    let delete_row2 = (item) => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record2/delete/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          id: item.id,
        },
        success(resp) {
          if (resp.error_message === "success") {
            refresh_content();
          } else {
            error_message.value = resp.error_message;
          }
        }
      })
    }

    let delete_row1 = (item) => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record1/delete/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          id: item.id,
        },
        success(resp) {
          if (resp.error_message === "success") {
            refresh_content();
          } else {
            error_message.value = resp.error_message;
          }
        }
      })
    }

    let get_money_unit_name = (moneyUnit) => {
      if (moneyUnit === 1) return "FCFA";
      else if (moneyUnit === 2) return "RMB";
      else if (moneyUnit === 3) return "USD";
      else if (moneyUnit === 4) return "EUR";
      return "";
    }

    let refresh_content = () => {
      getrecord1();
      getrecord2();
    }
    refresh_content();

    let phone_number1 = ref();
    let retrieve_by_phone1 = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record1/getbyphone/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          username: phone_number1.value
        },
        success(resp) {
          data1.value = resp;
        }
      })
    }

    let phone_number2 = ref();
    let retrieve_by_phone2 = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record2/getbyphone/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          username: phone_number2.value
        },
        success(resp) {
          data2.value = resp;
        }
      })
    }

    return {
      data1,
      data2,

      delete_row2,
      error_message,
      delete_row1,

      showDialog,
      dialogVisible,
      image_url,

      get_money_unit_name,

      retrieve_by_phone1,
      phone_number1,

      phone_number2,
      retrieve_by_phone2
    }
  }
}
</script>

<style scoped>

</style>