<template>
  <ContentField>
    <div class="row justify-content-md-center">
        <div class="col">
          <form @submit.prevent="login">
            <div class="mb-3">
              <label for="username" class="form-label">Numéro de téléphone </label>
              <input v-model="username" type="text" class="form-control" id="username" placeholder="Saisir le numéro de téléphone">
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Mot de passe </label>
              <input v-model="password" type="password" class="form-control" id="password" placeholder="Saisir le mot de passe">
            </div>
            <div class="error-message">{{ error_message }}</div>
            <button type="submit" class="btn btn-primary">Se connecter</button>
          </form>
        </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import { useStore } from 'vuex';
import {ref} from "vue";
import router from "@/router";

export default {
  name: "LoginView",
  components: {
    ContentField
  },
  setup() {
    const store = useStore();
    let username = ref('');
    let password = ref('');
    let error_message = ref('');

    const jwt_token = localStorage.getItem("jwt_token");

    if (jwt_token) {
      store.commit("updateToken", jwt_token);
      store.dispatch("getinfo", {
        success() {
          router.push({ name: "home" });
        },
      })
    }


    const login = () => {
      store.dispatch("login", {
        username: username.value,
        password: password.value,
        success() {
          store.dispatch("getinfo", {
            success() {
              router.push({ name: 'home' });
            }
          });
        },
        error() {
          error_message.value = "Nom d'utilisateur ou mot de passe incorrecte";
        }
      })
    }

    return {
      login,

      username,
      password,
      error_message
    }
  }
}
</script>

<style scoped>
button {
  width: 100%;
}

div.error-message {
  color: red;
}
</style>