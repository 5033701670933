<template>
  <nav class="navbar navbar-fixed-top navbar-expand-lg navbar-dark bg-primary">
    <div class="container container-fluid">
      <img src="@/assets/images/bnlogo.png" alt="..." style="width: 80px; margin-right: 10px">
      <router-link class="navbar-brand" :to="{name: 'home'}">Express Pay</router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link :class="route_name === 'home' ? 'nav-link active' : 'nav-link'" aria-current="page" :to="{name: 'home'}">Page d'accueil</router-link>
          </li>

        </ul>
        <ul class="navbar-nav" v-if="$store.state.user.is_login">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {{ $store.state.user.name }}
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" :to="{name: 'user_info'}">Information personnelle</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'change_password'}">Modifier le mot de passe </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'upload_barcode'}">Mode de Paiement </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'my_record'}">Mon historique </router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'change_variable'}" v-if="$store.state.user.id === '1'">Changement de variable</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'reset_password'}" v-if="$store.state.user.id === '1'">reset password</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'user_all'}" v-if="$store.state.user.id === '1'">All user</router-link>
              </li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'record_all'}" v-if="$store.state.user.id === '1'">All record</router-link>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <router-link class="dropdown-item" :to="{name: 'home'}" role="button" aria-expanded="true" @click="logout">Se déconnecter</router-link>
              </li>
            </ul>
          </li>
        </ul>

        <ul class="navbar-nav" v-else>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'login'}" role="button" aria-expanded="true">
              Se connecter
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'register'}" role="button" aria-expanded="true">
              S'enregistrer
            </router-link>
          </li>
        </ul>

      </div>
    </div>
  </nav>
</template>

<script>
import { useRoute } from 'vue-router';
import { computed } from "vue";
import { useStore } from 'vuex';

export default {
  name: "NarBar",
  setup() {
    const store = useStore();
    const route = useRoute();
    let route_name = computed(() => route.name);

    let logout = () => {
      store.dispatch("logout");
    }
    return {
      route_name,
      logout
    }
  }
}
</script>

<style scoped>

</style>