<template>
  <ContentField>
    <div class="carousel">
      <div class="slides" :style="{ transform: 'translateX(' + (-currentIndex * 100) + '%)' }">
        <a v-for="(slide, index) in slides" :key="index" target="_blank" :href="slide.url" class="slide" style="width: 100%; height: 120px; margin-bottom: 16px;">
          <img :src="slide.image" alt="Slide" class="image_advertising">
        </a>
      </div>
      <button @click="prevSlide" class="control prev">Previous</button>
      <button @click="nextSlide" class="control next">Next</button>
    </div>

    <div style="width: 100%; height: 120px; margin-bottom: 16px;">
      <img alt="Logo" src="@/assets/images/img.png" style="height: 100%; display: block; margin-left: auto; margin-right: auto;">
    </div>
    <div class="container text-center">
      <div class="row">
        <button class="col" @click="btn5_submit" :style="{ backgroundColor: btn5_color }">
          ENVOYER VIA PAYPAL
        </button>
        <button class="col" @click="btn6_submit" :style="{ backgroundColor: btn6_color }">
          RECEVOIR VIA PAYPAL
        </button>
      </div>
      <div class="row">
        <button class="col" @click="btn1_submit" :style="{ backgroundColor: btn1_color }">
          GABON/SENEGAL VERS CHINE
        </button>
        <button class="col" @click="btn2_submit" :style="{ backgroundColor: btn2_color }">
          CREDIT
        </button>
      </div>
      <div class="row">
        <button class="col" @click="btn3_submit" :style="{ backgroundColor: btn3_color }">
          CHINE VERS GABON/SENEGAL
        </button>
        <button class="col" @click="btn4_submit" :style="{ backgroundColor: btn4_color }">
          GABON VERS SENEGAL
        </button>
      </div>
    </div>

    <div class="container" style="padding: 6px">
      <div class="card">
        <div class="card-body" v-if="show_a5">
          <div class="mb">
            <label class="form-label">Montant à envoyer</label>
            <div class="main-content bg-primary">
              <input v-model="data15" type="text" class="form-control" placeholder="Saisir le montant à envoyer">
              <span class="long-text">
                <select v-model="fcfa_rmb_one">
                  <option value="FCFA">FCFA</option>
                  <option value="RMB">RMB</option>
                </select>
              </span>
<!--              <span>RMB</span>-->
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Frais</label>
            <div class="main-content bg-primary">
              <input :value="data16" type="text" class="form-control" readonly disabled>
<!--              <span>RMB</span>-->
              <span class="long-text">
                <select v-model="fcfa_rmb_one">
                  <option value="FCFA">FCFA</option>
                  <option value="RMB">RMB</option>
                </select>
              </span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant frais inclus </label>
            <div class="main-content bg-primary">
              <input v-model="data17" type="text" class="form-control" placeholder="Saisir le montant frais inclus">
<!--              <span>RMB</span>-->
              <span class="long-text">
                <select v-model="fcfa_rmb_one">
                  <option value="FCFA">FCFA</option>
                  <option value="RMB">RMB</option>
                </select>
              </span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant à recevoir</label>
            <div class="main-content bg-primary">
              <input :value="data18" type="text" class="form-control" readonly disabled>
              <span class="long-text">
                <select v-model="usd_eur_one">
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </select>
              </span>
            </div>
          </div>

          <div class="mb">
            <label class="form-label">Télécharger la photo</label>
            <el-upload
                v-model:file-list="fileList"
                action="https://expresspaybn.com/api/upload/"
                :headers="headers"
                list-type="picture-card"
                :on-change="uploadPicture"
                :on-preview="handlePictureCardPreview"
                :on-remove="handle_remove"
                :limit="3"
                :on-exceed="maxFileLimit"
                :before-upload="beforeUpload"
                name="image"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
            <div class="error-message">{{ image_error_message }}</div>
          </div>

          <div class="error-message">{{ error_message5 }}</div>
          <button style="width: 100%" type="submit" class="btn btn-primary save-btn" @click="save5">Sauvegarder</button>
        </div>
        <div class="card-body" v-if="show_a6">
          <div class="mb">
            <label class="form-label">Montant à envoyer</label>
            <div class="main-content bg-primary">
              <input v-model="data19" type="text" class="form-control" placeholder="Saisir le montant à envoyer">
<!--              <span>USD</span>-->
              <span class="long-text">
                <select v-model="usd_eur_two">
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </select>
              </span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Frais</label>
            <div class="main-content bg-primary">
              <input :value="data20" type="text" class="form-control" readonly disabled>
<!--              <span>USD</span>-->
              <span class="long-text">
                <select v-model="usd_eur_two">
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </select>
              </span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant frais inclus </label>
            <div class="main-content bg-primary">
              <input v-model="data21" type="text" class="form-control" placeholder="Saisir le montant frais inclus">
<!--              <span>USD</span>-->
              <span class="long-text">
                <select v-model="usd_eur_two">
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </select>
              </span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant à recevoir</label>
            <div class="main-content bg-primary">
              <input :value="data22" type="text" class="form-control" readonly disabled>
              <span class="long-text">
                <select v-model="fcfa_rmb_two">
                  <option value="FCFA">FCFA</option>
                  <option value="RMB">RMB</option>
                </select>
              </span>
            </div>
          </div>

          <div class="mb">
            <label class="form-label">Télécharger la photo</label>
            <el-upload
                v-model:file-list="fileList"
                action="https://expresspaybn.com/api/upload/"
                :headers="headers"
                list-type="picture-card"
                :on-change="uploadPicture"
                :on-preview="handlePictureCardPreview"
                :on-remove="handle_remove"
                :limit="3"
                :on-exceed="maxFileLimit"
                :before-upload="beforeUpload"
                name="image"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
            <div class="error-message">{{ image_error_message }}</div>
          </div>

          <div class="error-message">{{ error_message6 }}</div>
          <button style="width: 100%" type="submit" class="btn btn-primary save-btn" @click="save6">Sauvegarder</button>
        </div>
        <div class="card-body" v-if="show_a1">
          <div class="mb">
            <label class="form-label">Montant à envoyer</label>
            <div class="main-content bg-primary">
              <input v-model="data1" type="text" class="form-control" placeholder="Saisir le montant à envoyer">
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Frais</label>
            <div class="main-content bg-primary">
              <input :value="data2" type="text" class="form-control" readonly disabled>
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant frais inclus </label>
            <div class="main-content bg-primary">
              <input v-model="data3" type="text" class="form-control" placeholder="Saisir le montant frais inclus">
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant à recevoir</label>
            <div class="main-content bg-primary">
              <input :value="data4" type="text" class="form-control" readonly disabled>
              <span>RMB</span>
            </div>
          </div>

          <div class="mb">
            <label class="form-label">Télécharger la photo</label>
            <el-upload
                v-model:file-list="fileList"
                action="https://expresspaybn.com/api/upload/"
                :headers="headers"
                list-type="picture-card"
                :on-change="uploadPicture"
                :on-preview="handlePictureCardPreview"
                :on-remove="handle_remove"
                :limit="3"
                :on-exceed="maxFileLimit"
                :before-upload="beforeUpload"
                name="image"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
            <div class="error-message">{{ image_error_message }}</div>
          </div>

          <div class="error-message">{{ error_message1 }}</div>
          <button style="width: 100%" type="submit" class="btn btn-primary save-btn" @click="save1">Sauvegarder</button>
        </div>
        <div class="card-body" v-if="show_a2">
          <div class="mb">
            <label class="form-label">Montant à prêter</label>
            <div class="main-content bg-primary">
              <input v-model="container2.data5" type="text" class="form-control" placeholder="Saisir le montant à prêter ">
              <span class="long-text">
                <select v-model="selectedCurrency">
                  <option value="FCFA">FCFA</option>
                  <option value="RMB">RMB</option>
                </select>
              </span>
            </div>
          </div>
          <p>Choisir le pourcentage:</p>
          <div class="mb">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" value="15" v-model="container2.selectedOption">
              <label class="form-check-label">
                Une semaine 15%
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" value="20" v-model="container2.selectedOption">
              <label class="form-check-label">
                Deux semaines 20%
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" value="30" v-model="container2.selectedOption">
              <label class="form-check-label">
                Trois semaines 30%
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="exampleRadios" value="40" v-model="container2.selectedOption">
              <label class="form-check-label">
                Un mois 40%
              </label>
            </div>
          </div>
          <br />
          <div class="mb">
            <label class="form-label">Montant à rembourser</label>
            <div class="main-content bg-primary">
              <input :value="data6" type="text" class="form-control" readonly disabled>
<!--              <span class="long-text">FCFA/RMB</span>-->
              <span class="long-text">
                <select v-model="selectedCurrency">
                  <option value="FCFA">FCFA</option>
                  <option value="RMB">RMB</option>
                </select>
              </span>
            </div>
          </div>
          <div class="mb-3">
            <label for="name" class="form-label">Nom et prénom</label>
            <input v-model="name" type="text" class="form-control" id="name" placeholder="Saisir le nom et prénom ">
          </div>
          <div class="mb-3">
            <label for="username" class="form-label">Numéro de téléphone </label>
            <input v-model="username" type="text" class="form-control" id="username" placeholder="Saisir le numéro de téléphone">
          </div>
          <div class="mb-3">
            <label for="passport" class="form-label">Numéro de passeport </label>
            <input v-model="passport" type="text" class="form-control" id="passport" placeholder="Saisir le numéro de passeport ">
          </div>
          <div class="mb-3">
            <label for="address" class="form-label">Addresse</label>
            <input v-model="address" type="text" class="form-control" id="address" placeholder="Saisir l'adresse">
          </div>

          <div class="mb">
            <label class="form-label">Passport et visa</label>
            <el-upload
                v-model:file-list="fileList"
                action="https://expresspaybn.com/api/upload/"
                :headers="headers"
                list-type="picture-card"
                :on-change="uploadPicture"
                :on-preview="handlePictureCardPreview"
                :on-remove="handle_remove"
                :limit="3"
                :on-exceed="maxFileLimit"
                :before-upload="beforeUpload"
                name="image"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
            <div class="error-message">{{ image_error_message }}</div>
          </div>

          <div class="error-message">{{ error_message2 }}</div>
          <button style="width: 100%" type="submit" class="btn btn-primary save-btn" @click="save2">Sauvegarder</button>
        </div>
        <div class="card-body" v-if="show_a3">
          <div class="mb">
            <label class="form-label">Montant à envoyer</label>
            <div class="main-content bg-primary">
              <input v-model="data8" type="text" class="form-control" placeholder="Saisir le montant à envoyer">
              <span>RMB</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Frais</label>
            <div class="main-content bg-primary">
              <input :value="data9" type="text" class="form-control" readonly disabled>
              <span>RMB</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant frais inclus </label>
            <div class="main-content bg-primary">
              <input v-model="data10" type="text" class="form-control" placeholder="Saisir le montant frais inclus">
              <span>RMB</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant à recevoir</label>
            <div class="main-content bg-primary">
              <input :value="data11" type="text" class="form-control" readonly disabled>
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb-3">
            <label for="name" class="form-label">Nom et prénom</label>
            <input v-model="name" type="text" class="form-control" placeholder="Saisir le nom et prénom ">
          </div>
          <div class="mb-3">
            <label for="username" class="form-label">Numéro de téléphone </label>
            <input v-model="username" type="text" class="form-control" placeholder="Saisir le numéro de téléphone">
          </div>
          <div class="error-message">{{ error_message3 }}</div>
          <button @click="save3" style="width: 100%" type="submit" class="btn btn-primary save-btn">Sauvegarder</button>
        </div>
        <div class="card-body" v-if="show_a4">
          <div class="mb">
            <label class="form-label">Montant à envoyer</label>
            <div class="main-content bg-primary">
              <input v-model="data12" type="text" class="form-control" placeholder="Saisir le montant à envoyer">
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Frais </label>
            <div class="main-content bg-primary">
              <input :value="data13" type="text" class="form-control" readonly disabled>
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant frais inclus </label>
            <div class="main-content bg-primary">
              <input v-model="data14" type="text" class="form-control" placeholder="Saisir le montant frais inclus">
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb">
            <label class="form-label">Montant à recevoir</label>
            <div class="main-content bg-primary">
              <input v-model="data12" type="text" class="form-control" readonly disabled>
              <span>FCFA</span>
            </div>
          </div>
          <div class="mb-3">
            <label for="name" class="form-label">Nom et prénom</label>
            <input v-model="name" type="text" class="form-control" placeholder="Saisir le nom et prénom ">
          </div>
          <div class="mb-3">
            <label for="username" class="form-label">Numéro de téléphone </label>
            <input v-model="username" type="text" class="form-control" placeholder="Saisir le numéro de téléphone">
          </div>

          <div class="mb">
            <label class="form-label">Télécharger la photo</label>
            <el-upload
                v-model:file-list="fileList"
                action="https://expresspaybn.com/api/upload/"
                :headers="headers"
                list-type="picture-card"
                :on-change="uploadPicture"
                :on-preview="handlePictureCardPreview"
                :on-remove="handle_remove"
                :limit="3"
                :on-exceed="maxFileLimit"
                :before-upload="beforeUpload"
                name="image"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
            <el-dialog v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
            <div class="error-message">{{ image_error_message }}</div>
          </div>

          <div class="error-message">{{ error_message4 }}</div>
          <button @click="save4" style="width: 100%" type="submit" class="btn btn-primary save-btn">Sauvegarder </button>
        </div>
      </div>
    </div>

  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import {reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import $ from 'jquery';
import { Plus } from '@element-plus/icons-vue';

export default {
  name: "HomeView",
  components: {
    ContentField,
    Plus
  },
  setup() {
    const store = useStore();

    let show_a1 = ref(false);
    let show_a2 = ref(false);
    let show_a3 = ref(false);
    let show_a4 = ref(false);
    let show_a5 = ref(true);
    let show_a6 = ref(false);

    let btn1_color = ref('#f2f2f2');
    let btn2_color = ref('#f2f2f2');
    let btn3_color = ref('#f2f2f2');
    let btn4_color = ref('#f2f2f2');
    let btn5_color = ref('#316cf4');
    let btn6_color = ref('#f2f2f2');

    let btn5_submit = () => {
      if (btn5_color.value !== '#316cf4') {
        btn5_color.value = '#316cf4';
        btn1_color.value = btn2_color.value = btn3_color.value = btn4_color.value = btn6_color.value = '#f2f2f2';
        show_a5.value = true;
        show_a1.value = show_a2.value = show_a3.value = show_a4.value = show_a6.value = false;
        clear_image()
      }
    }

    let btn6_submit = () => {
      if (btn1_color.value !== '#316cf4') {
        btn6_color.value = '#316cf4';
        btn5_color.value = btn4_color.value = btn1_color.value = btn2_color.value = btn3_color.value = '#f2f2f2';
        show_a6.value = true;
        show_a1.value = show_a2.value = show_a3.value = show_a4.value = show_a5.value = false;
        clear_image()
      }
    }

    let btn1_submit = () => {
      if (btn1_color.value !== '#316cf4') {
        btn1_color.value = '#316cf4';
        btn2_color.value = btn3_color.value = btn4_color.value = btn5_color.value = btn6_color.value = '#f2f2f2';
        show_a1.value = true;
        show_a2.value = show_a3.value = show_a4.value = show_a5.value = show_a6.value = false;
        clear_image()
      }
    }

    let btn2_submit = () => {
      if (btn2_color.value !== '#316cf4') {
        btn2_color.value = '#316cf4';
        btn1_color.value = btn3_color.value = btn4_color.value = btn5_color.value = btn6_color.value = '#f2f2f2';
        show_a2.value = true;
        show_a1.value = show_a3.value = show_a4.value = show_a5.value = show_a6.value = false;
        clear_image()
      }
    }

    let btn3_submit = () => {
      if (btn3_color.value !== '#316cf4') {
        btn3_color.value = '#316cf4';
        btn1_color.value = btn2_color.value = btn4_color.value = btn5_color.value = btn6_color.value = '#f2f2f2';
        show_a3.value = true;
        show_a1.value = show_a2.value = show_a4.value = show_a5.value = show_a6.value = false;
        clear_image()
      }
    }

    let btn4_submit = () => {
      if (btn4_color.value !== '#316cf4') {
        btn4_color.value = '#316cf4';
        btn1_color.value = btn2_color.value = btn3_color.value = btn5_color.value = btn6_color.value = '#f2f2f2';
        show_a4.value = true;
        show_a1.value = show_a2.value = show_a3.value = show_a5.value = show_a6.value = false;
        clear_image()
      }
    }

    let data1 = ref('');
    let data2 = ref('');
    let data3 = ref('');
    let data4 = ref('');
    let error_message1 = ref('');
    let error_message2 = ref('');
    let error_message3 = ref('');
    let error_message4 = ref('');

    watch(data1, (newValue) => {
      if (data1.value !== "") {
        data2.value = (parseFloat(newValue) * parseFloat(store.state.var.var1) / 100.0).toFixed(3);
        data3.value = Math.round(parseFloat(newValue) + parseFloat(newValue) * parseFloat(store.state.var.var1) / 100).toString();
        data4.value = (parseFloat(newValue) / parseFloat(store.state.var.var4)).toFixed(3);
      }
    });

    watch(data3, (newValue) => {
      if (data3.value !== "") {
        data1.value = Math.round(parseFloat(newValue) / (parseFloat(store.state.var.var1) + 100) * 100).toString();
        data2.value = (parseFloat(newValue) / (parseFloat(store.state.var.var1) + 100) * parseFloat(store.state.var.var1)).toFixed(3);
        data4.value = (parseFloat(data1.value) / parseFloat(store.state.var.var4)).toFixed(3);
      }
    });

    let data6 = ref('');

    let container2 = reactive({
      data5: '',
      selectedOption: ''
    });

    let name = ref('');
    let passport = ref('');
    let address = ref('');
    let username = ref('');

    watch(
        [() => container2.data5, () => container2.selectedOption],
        ([newVal1, newVal2]) => {
          if (newVal1 !== "" && newVal2 !== "") {
            data6.value = (parseFloat(newVal1) + parseFloat(newVal1) * parseFloat(newVal2) / 100).toString();
          } else {
            data6.value = "";
          }
        },
        { deep: true }
    );

    let data8 = ref('');
    let data9 = ref('');
    let data10 = ref('');
    let data11 = ref('');

    watch(data8, (newValue) => {
      if (newValue !== "") {
        data9.value = (parseFloat(newValue) * parseFloat(store.state.var.var2) / 100).toFixed(3);
        data10.value = Math.round(parseFloat(newValue) + parseFloat(newValue) * parseFloat(store.state.var.var2) / 100).toString();
        data11.value = (parseFloat(newValue) * parseFloat(store.state.var.var5)).toFixed(3);
      }
    });

    watch(data10, (newValue) => {
      if (newValue !== "") {
        data8.value = Math.round(parseFloat(newValue) / (parseFloat(store.state.var.var2) + 100) * 100).toString();
        data9.value = (parseFloat(newValue) / (parseFloat(store.state.var.var2) + 100) * parseFloat(store.state.var.var2)).toFixed(3);
        data11.value = (parseFloat(data8.value) * parseFloat(store.state.var.var5)).toFixed(3);
      }
    });

    let data12 = ref('');
    let data13 = ref('');
    let data14 = ref('');

    watch(data12, (newValue) => {
      if (newValue !== "") {
        data13.value = (parseFloat(newValue) * parseFloat(store.state.var.var3) / 100.0).toFixed(3);
        data14.value = Math.round(parseFloat(newValue) + parseFloat(newValue) * parseFloat(store.state.var.var3) / 100).toString();
      }
    });

    watch(data14, (newValue) => {
      if (newValue !== "") {
        data12.value = Math.round(parseFloat(newValue) / (parseFloat(store.state.var.var1) + 100) * 100).toString();
        data13.value = (parseFloat(newValue) / (parseFloat(store.state.var.var3) + 100) * parseFloat(store.state.var.var3)).toFixed(3);
      }
    });

    let data15 = ref('');
    let data16 = ref('');
    let data17 = ref('');
    let data18 = ref('');
    let fcfa_rmb_one = ref('RMB')
    let usd_eur_one = ref('USD')
    let error_message5 = ref('');

    watch(
        [() => data15.value, () => fcfa_rmb_one.value, () => usd_eur_one.value],
        // eslint-disable-next-line no-unused-vars
        ([data15NewValue, fcfaRmbNewValue, usdEurNewValue]) => {
      if (data15.value !== "") {
        data16.value = (parseFloat(data15NewValue) * parseFloat(store.state.var.var8) / 100.0).toFixed(3);
        data17.value = Math.round(parseFloat(data15NewValue) + parseFloat(data15NewValue) * parseFloat(store.state.var.var8) / 100).toString();
        if (fcfa_rmb_one.value === "FCFA" && usd_eur_one.value === "USD") {
          data18.value = (parseFloat(data15NewValue) / parseFloat(store.state.var.var10)).toFixed(3);
        } else if (fcfa_rmb_one.value === "FCFA" && usd_eur_one.value === "EUR") {
          data18.value = (parseFloat(data15NewValue) / parseFloat(store.state.var.var11)).toFixed(3);
        } else if (fcfa_rmb_one.value === "RMB" && usd_eur_one.value === "USD") {
          data18.value = (parseFloat(data15NewValue) / parseFloat(store.state.var.var6)).toFixed(3);
        } else if (fcfa_rmb_one.value === "RMB" && usd_eur_one.value === "EUR") {
          data18.value = (parseFloat(data15NewValue) / parseFloat(store.state.var.var12)).toFixed(3);
        } else {
          data18.value = ""
        }
      }
    });

    watch(
        [() => data17.value, () => fcfa_rmb_one.value, () => usd_eur_one.value],
        // eslint-disable-next-line no-unused-vars
        ([newValue, fcfaRmbNewValue, usdEurNewValue]) => {
      if (data17.value !== "") {
        data15.value = Math.round(parseFloat(newValue) / (parseFloat(store.state.var.var8) + 100) * 100).toString();
        data16.value = (parseFloat(newValue) / (parseFloat(store.state.var.var8) + 100) * parseFloat(store.state.var.var8)).toFixed(3);
        if (fcfa_rmb_one.value === "FCFA" && usd_eur_one.value === "USD") {
          data18.value = (parseFloat(data15.value) / parseFloat(store.state.var.var10)).toFixed(3);
        } else if (fcfa_rmb_one.value === "FCFA" && usd_eur_one.value === "EUR") {
          data18.value = (parseFloat(data15.value) / parseFloat(store.state.var.var11)).toFixed(3);
        } else if (fcfa_rmb_one.value === "RMB" && usd_eur_one.value === "USD") {
          data18.value = (parseFloat(data15.value) / parseFloat(store.state.var.var6)).toFixed(3);
        } else if (fcfa_rmb_one.value === "RMB" && usd_eur_one.value === "EUR") {
          data18.value = (parseFloat(data15.value) / parseFloat(store.state.var.var12)).toFixed(3);
        } else {
          data18.value = ""
        }
      }
    });

    let data19 = ref('');
    let data20 = ref('');
    let data21 = ref('');
    let data22 = ref('');
    let error_message6 = ref('');
    let usd_eur_two = ref('USD');
    let fcfa_rmb_two = ref('FCFA')

    watch(
        [() => data19.value, () => fcfa_rmb_two.value, () => usd_eur_two.value],
        // eslint-disable-next-line no-unused-vars
        ([newValue, fcfaRmbNewValue, usdEurNewValue]) => {
      if (newValue !== "") {
        data20.value = (parseFloat(newValue) * parseFloat(store.state.var.var9) / 100).toFixed(3);
        data21.value = Math.round(parseFloat(newValue) + parseFloat(newValue) * parseFloat(store.state.var.var9) / 100).toString();
        if (fcfa_rmb_two.value === "FCFA" && usd_eur_two.value === "USD") {
          data22.value = (parseFloat(newValue) * parseFloat(store.state.var.var13)).toFixed(3);
        } else if (fcfa_rmb_two.value === "FCFA" && usd_eur_two.value === "EUR") {
          data22.value = (parseFloat(newValue) * parseFloat(store.state.var.var14)).toFixed(3);
        } else if (fcfa_rmb_two.value === "RMB" && usd_eur_two.value === "USD") {
          data22.value = (parseFloat(newValue) * parseFloat(store.state.var.var7)).toFixed(3);
        } else if (fcfa_rmb_two.value === "RMB" && usd_eur_two.value === "EUR") {
          data22.value = (parseFloat(newValue) * parseFloat(store.state.var.var15)).toFixed(3);
        } else {
          data22.value = ""
        }
        // data22.value = (parseFloat(newValue) * parseFloat(store.state.var.var7)).toFixed(3);
      }
    });

    watch(
        [() => data21.value, () => fcfa_rmb_two.value, () => usd_eur_two.value],
        // eslint-disable-next-line no-unused-vars
        ([newValue, fcfaRmbNewValue, usdEurNewValue]) => {
      if (newValue !== "") {
        data19.value = Math.round(parseFloat(newValue) / (parseFloat(store.state.var.var9) + 100) * 100).toString();
        data20.value = (parseFloat(newValue) / (parseFloat(store.state.var.var9) + 100) * parseFloat(store.state.var.var9)).toFixed(3);
        if (fcfa_rmb_two.value === "FCFA" && usd_eur_two.value === "USD") {
          data22.value = (parseFloat(data19.value) * parseFloat(store.state.var.var13)).toFixed(3);
        } else if (fcfa_rmb_two.value === "FCFA" && usd_eur_two.value === "EUR") {
          data22.value = (parseFloat(data19.value) * parseFloat(store.state.var.var14)).toFixed(3);
        } else if (fcfa_rmb_two.value === "RMB" && usd_eur_two.value === "USD") {
          data22.value = (parseFloat(data19.value) * parseFloat(store.state.var.var7)).toFixed(3);
        } else if (fcfa_rmb_two.value === "RMB" && usd_eur_two.value === "EUR") {
          data22.value = (parseFloat(data19.value) * parseFloat(store.state.var.var15)).toFixed(3);
        } else {
          data22.value = ""
        }
        // data22.value = (parseFloat(data19.value) * parseFloat(store.state.var.var7)).toFixed(3);
      }
    });

    let save1 = () => {
      if (file_url.length === 0) {
        image_error_message.value = "veillez choisir une photo";
        return;
      } else if (file_url.length === 1) {
        file_url.push("");
        file_url.push("");
      } else if (file_url.length === 2) {
        file_url.push("");
      }
      $.ajax({
        url: 'https://expresspaybn.com/api/record/save1/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          type: 1,
          envoyer: data1.value,
          frais: data2.value,
          inclus: data3.value,
          recevoir: data4.value,
          name: store.state.user.name,
          username: store.state.user.username,
          image1_url: file_url[0],
          image2_url: file_url[1],
          image3_url: file_url[2],
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message1.value = "Sauvegardé avec succès";
          } else {
            error_message1.value = resp.error_message;
          }
        }
      })
    }

    let save2 = () => {
      if (file_url.length === 0) {
        image_error_message.value = "veillez choisir une photo";
        return;
      } else if (file_url.length === 1) {
        file_url.push("");
        file_url.push("");
      } else if (file_url.length === 2) {
        file_url.push("");
      }
      $.ajax({
        url: 'https://expresspaybn.com/api/record2/save/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          montant: container2.data5,
          rate: container2.selectedOption,
          rembourser: data6.value,
          money_unit: get_money_unit(selectedCurrency.value),
          passport: passport.value,
          name: name.value,
          username: username.value,
          address: address.value,
          image1_url: file_url[0],
          image2_url: file_url[1],
          image3_url: file_url[2],
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message2.value = "Sauvegardé avec succès";
          } else {
            error_message2.value = resp.error_message;
          }
        }
      })
    }

    let save3 = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record/save1/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          type: 2,
          envoyer: data8.value,
          frais: data9.value,
          inclus: data10.value,
          recevoir: data11.value,
          name: name.value,
          username: username.value,
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message3.value = "Sauvegardé avec succès";
          } else {
            error_message3.value = resp.error_message;
          }
        }
      })
    }

    let save4 = () => {
      if (file_url.length === 0) {
        image_error_message.value = "veillez choisir une photo";
        return;
      } else if (file_url.length === 1) {
        file_url.push("");
        file_url.push("");
      } else if (file_url.length === 2) {
        file_url.push("");
      }
      $.ajax({
        url: 'https://expresspaybn.com/api/record/save1/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          type: 3,
          envoyer: data12.value,
          frais: data13.value,
          inclus: data14.value,
          recevoir: data12.value,
          name: name.value,
          username: username.value,
          image1_url: file_url[0],
          image2_url: file_url[1],
          image3_url: file_url[2],
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message4.value = "Sauvegardé avec succès";
          } else {
            error_message4.value = resp.error_message;
          }
        }
      })
    }

    let get_money_unit = (moneyUnit) => {
      if (moneyUnit === "FCFA") {
        return 1;
      } else if (moneyUnit === "RMB") {
        return 2;
      } else if (moneyUnit === "USD") {
        return 3;
      } else return 4;
    }

    let save5 = () => {
      if (file_url.length === 0) {
        image_error_message.value = "veillez choisir une photo";
        return;
      } else if (file_url.length === 1) {
        file_url.push("");
        file_url.push("");
      } else if (file_url.length === 2) {
        file_url.push("");
      }
      $.ajax({
        url: 'https://expresspaybn.com/api/record/save1/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          type: 5,
          envoyer: data15.value,
          frais: data16.value,
          inclus: data17.value,
          recevoir: data18.value,
          money_unit_one: get_money_unit(fcfa_rmb_one.value),
          money_unit_two: get_money_unit(usd_eur_one.value),
          name: store.state.user.name,
          username: store.state.user.username,
          image1_url: file_url[0],
          image2_url: file_url[1],
          image3_url: file_url[2],
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message5.value = "Sauvegardé avec succès";
          } else {
            error_message5.value = resp.error_message;
          }
        }
      })
    }

    let save6 = () => {
      if (file_url.length === 0) {
        image_error_message.value = "veillez choisir une photo";
        return;
      } else if (file_url.length === 1) {
        file_url.push("");
        file_url.push("");
      } else if (file_url.length === 2) {
        file_url.push("");
      }
      $.ajax({
        url: 'https://expresspaybn.com/api/record/save1/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        data: {
          type: 6,
          envoyer: data19.value,
          frais: data20.value,
          inclus: data21.value,
          recevoir: data22.value,
          money_unit_one: get_money_unit(usd_eur_two.value),
          money_unit_two: get_money_unit(fcfa_rmb_two.value),
          name: store.state.user.name,
          username: store.state.user.username,
          image1_url: file_url[0],
          image2_url: file_url[1],
          image3_url: file_url[2],
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message6.value = "Sauvegardé avec succès";
          } else {
            error_message6.value = resp.error_message;
          }
        }
      })
    }

    let headers = ref({
      Authorization: "Bearer " + store.state.user.token,
    });
    let fileList = ref([]);
    let file_url = [];
    let dialogImageUrl = ref('');
    let dialogVisible = ref(false);
    let image_error_message = ref("");

    let uploadPicture = () => {
      file_url = [];
      for (let i = 0; i < fileList.value.length; i++) {
        file_url.push(fileList.value[i].response.image);
        image_error_message.value = "";
      }
    }

    let handlePictureCardPreview = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    }

    let handle_remove = (uploadFiles, uploadFile) => {
      console.log(uploadFile)
      file_url = [];
      for (let i = 0; i < uploadFiles.length; i++) {
        file_url.push(uploadFiles[i].response.image);
      }
    }

    let beforeUpload = (file) => {
      // 获取上传文件的大小（以字节为单位）
      let fileSize = file.size;

      // 定义允许的最大文件大小（以字节为单位）
      let maxSize = 5 * 1024 * 1024; // 5MB
      console.log(fileSize)

      // 检查文件大小是否超过允许的最大值
      if (fileSize > maxSize) {
        // 文件大小超过限制，拒绝上传
        //this.$message.error('文件大小超过限制（最大5MB）');
        return false;
      }

      // 文件大小符合要求，允许上传
      return true;
    }

    let maxFileLimit = () => {
      image_error_message.value = "Vous ne pouvez pas télécharger plus de 3 photos "
    }

    let clear_image = () => {
      image_error_message.value = "";
      fileList.value = [];
      file_url = [];
      dialogVisible.value = false;
      dialogImageUrl.value = "";
    }

    let currentIndex = ref(0);
    let slides = ref([
      {image: "/img_1.png", url: 'https://gabonaisdechine.com/'},
      {image: "/img_2.png", url: 'https://www.facebook.com/SUCCESSDIAMONDPAGE?mibextid=LQQJ4d'},
      {image: "/img_3.png", url: 'https://www.facebook.com/luxury.beauty.241'},
      {image: "/img_4.png", url: 'https://medinchina.me/'},
      {image: "/img_5.png", url: 'https://www.instagram.com/mymy.shop__gabon?igsh=bndlMGplZTE3YTlt&utm_source=qr'},
      {image: "/img_6.png", url: 'https://www.instagram.com/mypick_store23?igsh=MWJrd2U5dTBiNXhyaA%3D%3D&utm_source=qr'},
      {image: "/img_7.png", url: 'https://www.instagram.com/patyehair?igsh=MXU5Z2hxYTMxbzlzYg%3D%3D&utm_source=qr'},
      {image: "/img_8.png", url: 'https://me-qr.com/zh/data/image-pack/88890787'},
    ]);

    let prevSlide = () => {
      currentIndex.value = (currentIndex.value - 1 + slides.value.length) % slides.value.length;
    };
    let nextSlide = () => {
      currentIndex.value = (currentIndex.value + 1) % slides.value.length;
    };

    let switch_image = () => {
      setInterval(nextSlide, 5000)
    }
    switch_image()

    let selectedCurrency = ref("FCFA");

    return {
      show_a1,
      show_a2,
      show_a3,
      show_a4,
      show_a5,
      show_a6,

      btn1_color,
      btn2_color,
      btn3_color,
      btn4_color,
      btn5_color,
      btn6_color,

      btn1_submit,
      btn2_submit,
      btn3_submit,
      btn4_submit,
      btn5_submit,
      btn6_submit,

      data1,
      data2,
      data3,
      data4,
      error_message1,

      data6,
      container2,
      passport,
      address,
      error_message2,
      name,
      username,

      data8,
      data9,
      data10,
      data11,
      error_message3,

      data12,
      data13,
      data14,
      error_message4,

      data15,
      data16,
      data17,
      data18,
      error_message5,

      data19,
      data20,
      data21,
      data22,
      error_message6,

      save1,
      save2,
      save3,
      save4,
      save5,
      save6,

      image_error_message,
      maxFileLimit,

      headers,
      fileList,
      handlePictureCardPreview,
      dialogVisible,
      dialogImageUrl,
      uploadPicture,
      handle_remove,
      beforeUpload,

      nextSlide,
      prevSlide,
      currentIndex,
      slides,

      selectedCurrency,
      fcfa_rmb_one,
      usd_eur_one,
      fcfa_rmb_two,
      usd_eur_two
    }
  }
}
</script>

<style scoped>
button {
  background-color: #f2f2f2;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 24px;
  transition: .3s;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 700;
  margin: 4px;
}

button.save-btn {
  background-color: #316cf4;
}

.main-content {
  border-radius: 16px;
  padding: 6px;
  float: left;
  width: 100%;
  margin: 6px 0 12px;
  display: flex;
}

span {
  padding: 10px;
  min-width: 65px;
  text-align: center;
  border-left: none;
  color: #fff;
  background-color: transparent;
  vertical-align: middle;
  font-size: .875rem;
}

span.long-text {
  width: 20px;
}

div.error-message {
  color: red;
}

.carousel {
  position: relative;
  overflow: hidden;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slide {
  flex: 0 0 100%;
}

.control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #316cf4;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.image_advertising {
  height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>