<template>
  <div class="container content-field">
    <div class="card">
      <div class="card-body">
        <slot></slot>
        <div class="container border border-success" style="margin-top: 20px">
          <img src="@/assets/images/code.png" style="width: 45%; height: auto;" class="rounded float-start" alt="code">
          <a href="https://wa.me/message/5N2GQVECVHRAN1">
            <img src="@/assets/images/phone.png" style="width: 45%; height: auto;" class="rounded float-end" alt="phone">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentField"
}
</script>

<style scoped>

div.content-field {
  margin-top: 20px;
}
</style>