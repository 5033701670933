<template>
  <ContentField>
    <div class="row justify-content-md-center">
      <div class="col">
        <form @submit.prevent="change">
          <div class="mb-3">
            <label for="variable1" class="form-label">Gabon Sénégal vers Chine frais
            </label>
            <input v-model="variable1" type="text" class="form-control" id="variable1">
          </div>
          <div class="mb-3">
            <label for="variable2" class="form-label">Chine vers Gabon Sénégal frais
            </label>
            <input v-model="variable2" type="text" class="form-control" id="variable2">
          </div>
          <div class="mb-3">
            <label for="variable8" class="form-label">Recevoir PayPal frais </label>
            <input v-model="variable8" type="text" class="form-control" id="variable8">
          </div>
          <div class="mb-3">
            <label for="variable9" class="form-label">envoyer PayPal Frais</label>
            <input v-model="variable9" type="text" class="form-control" id="variable9">
          </div>
          <div class="mb-3">
            <label for="variable3" class="form-label">Gabon Sénégal frais</label>
            <input v-model="variable3" type="text" class="form-control" id="variable3">
          </div>
          <div class="mb-3">
            <label for="variable4" class="form-label">Gabon Sénégal vers chine taux</label>
            <input v-model="variable4" type="text" class="form-control" id="variable4">
          </div>
          <div class="mb-3">
            <label for="variable5" class="form-label">Chine vers Gabon Sénégal taux</label>
            <input v-model="variable5" type="text" class="form-control" id="variable5">
          </div>
          <div class="mb-3">
            <label for="variable6" class="form-label">RMB to USD</label>
            <input v-model="variable6" type="text" class="form-control" id="variable6">
          </div>
          <div class="mb-3">
            <label for="variable7" class="form-label">USD to RMB</label>
            <input v-model="variable7" type="text" class="form-control" id="variable7">
          </div>

          <div class="mb-3">
            <label for="variable10" class="form-label">FCFA to USD</label>
            <input v-model="variable10" type="text" class="form-control" id="variable10">
          </div>
          <div class="mb-3">
            <label for="variable11" class="form-label">FCFA to EUR</label>
            <input v-model="variable11" type="text" class="form-control" id="variable11">
          </div>
          <div class="mb-3">
            <label for="variable12" class="form-label">RMB to EUR</label>
            <input v-model="variable12" type="text" class="form-control" id="variable12">
          </div>
          <div class="mb-3">
            <label for="variable13" class="form-label">USD to FCFA</label>
            <input v-model="variable13" type="text" class="form-control" id="variable13">
          </div>
          <div class="mb-3">
            <label for="variable14" class="form-label">EUR to FCFA</label>
            <input v-model="variable14" type="text" class="form-control" id="variable14">
          </div>
          <div class="mb-3">
            <label for="variable15" class="form-label">EUR to RMB</label>
            <input v-model="variable15" type="text" class="form-control" id="variable15">
          </div>


          <div class="error-message">{{ error_message }}</div>
          <button type="submit" class="btn btn-primary">Sauvegarder</button>
        </form>
      </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import {ref} from "vue";
import {useStore} from "vuex";
import $ from 'jquery';

export default {
  name: "ChangeVariableView",
  components: {ContentField},
  setup() {
    const store = useStore();

    let variable1 = ref('');
    let variable2 = ref('');
    let variable3 = ref('');
    let variable4 = ref('');
    let variable5 = ref('');
    let variable6 = ref('');
    let variable7 = ref('');
    let variable8 = ref('');
    let variable9 = ref('');
    let variable10 = ref('');
    let variable11 = ref('');
    let variable12 = ref('');
    let variable13 = ref('');
    let variable14 = ref('');
    let variable15 = ref('');

    let error_message = ref('');

    let refresh_content = () => {
      store.dispatch("getvar");

      variable1.value = store.state.var.var1;
      variable2.value = store.state.var.var2;
      variable3.value = store.state.var.var3;
      variable4.value = store.state.var.var4;
      variable5.value = store.state.var.var5;
      variable6.value = store.state.var.var6;
      variable7.value = store.state.var.var7;
      variable8.value = store.state.var.var8;
      variable9.value = store.state.var.var9;
      variable10.value = store.state.var.var10;
      variable11.value = store.state.var.var11;
      variable12.value = store.state.var.var12;
      variable13.value = store.state.var.var13;
      variable14.value = store.state.var.var14;
      variable15.value = store.state.var.var15;
    }

    refresh_content();

    let change = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/var/change/',
        type: 'post',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        async: false,
        data: {
          var1: variable1.value,
          var2: variable2.value,
          var3: variable3.value,
          var4: variable4.value,
          var5: variable5.value,
          var6: variable6.value,
          var7: variable7.value,
          var8: variable8.value,
          var9: variable9.value,
          var10: variable10.value,
          var11: variable11.value,
          var12: variable12.value,
          var13: variable13.value,
          var14: variable14.value,
          var15: variable15.value,
        },
        success(resp) {
          if (resp.error_message === "success") {
            error_message.value = resp.error_message;
            refresh_content();
          } else {
            error_message.value = resp.error_message;
          }
        }
      })
    }

    return {
      variable1,
      variable2,
      variable3,
      variable4,
      variable5,
      variable6,
      variable7,
      variable8,
      variable9,
      variable10,
      variable11,
      variable12,
      variable13,
      variable14,
      variable15,

      error_message,

      change
    }
  }
}
</script>

<style scoped>
button {
  width: 100%;
}

div.error-message {
  color: red;
}
</style>