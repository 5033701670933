import $ from 'jquery';

export default {
    state: {
        var1: "",
        var2: "",
        var3: "",
        var4: "",
        var5: "",
        var6: "",
        var7: "",
        var8: "",
        var9: "",
        var10: "",
        var11: "",
        var12: "",
        var13: "",
        var14: "",
        var15: "",
    },
    getters: {
    },
    mutations: {
        updateVar(state, variable) {
            state.var1 = variable.var1;
            state.var2 = variable.var2;
            state.var3 = variable.var3;
            state.var4 = variable.var4;
            state.var5 = variable.var5;
            state.var6 = variable.var6;
            state.var7 = variable.var7;
            state.var8 = variable.var8;
            state.var9 = variable.var9;
            state.var10 = variable.var10;
            state.var11 = variable.var11;
            state.var12 = variable.var12;
            state.var13 = variable.var13;
            state.var14 = variable.var14;
            state.var15 = variable.var15;
        },
    },
    actions: {
        getvar(context) {
            $.ajax({
                url: 'https://expresspaybn.com/api/var/get/',
                type: 'get',
                async: false,
                success(resp) {
                    if (resp.error_message === "success") {
                        context.commit("updateVar", {...resp});
                        localStorage.setItem("var1", resp.var1);
                        localStorage.setItem("var2", resp.var2);
                        localStorage.setItem("var3", resp.var3);
                        localStorage.setItem("var4", resp.var4);
                        localStorage.setItem("var5", resp.var5);
                        localStorage.setItem("var6", resp.var6);
                        localStorage.setItem("var7", resp.var7);
                        localStorage.setItem("var8", resp.var8);
                        localStorage.setItem("var9", resp.var9);
                        localStorage.setItem("var10", resp.var10);
                        localStorage.setItem("var11", resp.var11);
                        localStorage.setItem("var12", resp.var12);
                        localStorage.setItem("var13", resp.var13);
                        localStorage.setItem("var14", resp.var14);
                        localStorage.setItem("var15", resp.var15);
                    }
                },
                error() {
                    localStorage.setItem("var1", '10');
                    localStorage.setItem("var2", '4');
                    localStorage.setItem("var3", '10');
                    localStorage.setItem("var4", '86');
                    localStorage.setItem("var5", '83');
                    localStorage.setItem("var6", '86');
                    localStorage.setItem("var7", '86');
                    localStorage.setItem("var8", '10');
                    localStorage.setItem("var9", '10');
                    localStorage.setItem("var10", '10');
                    localStorage.setItem("var11", '10');
                    localStorage.setItem("var12", '10');
                    localStorage.setItem("var13", '10');
                    localStorage.setItem("var14", '10');
                    localStorage.setItem("var15", '10');
                    let res = {
                        "var1": '10',
                        "var2": '4',
                        "var3": '10',
                        "var4": '86',
                        "var5": '80',
                        "var6": '86',
                        "var7": '86',
                        "var8": '10',
                        "var9": '10',
                        "var10": '10',
                        "var11": '10',
                        "var12": '10',
                        "var13": '10',
                        "var14": '10',
                        "var15": '10',
                    };
                    context.commit("updateVar", {...res});
                }
            })
        }
    },
    modules: {
    }
}