import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from '@/store';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

const app = createApp(App);

app.use(router).use(store).use(ElementPlus).use(TawkMessengerVue, {
    propertyId : '651120a6b1aaa13b7a78b7c8',
    widgetId : '1hb5ef45l'
});

app.mount("#app");
