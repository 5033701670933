<template>
  <ContentField>
    <div class="row justify-content-md-center">
      <div class="col">
        <form @submit.prevent="register">
          <div class="mb-3">
            <label for="username" class="form-label">Numéro de téléphone </label>
            <input v-model="username" type="text" class="form-control" id="username" placeholder="Saisir le numéro de téléphone ">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Mot de passe</label>
            <input v-model="password" type="password" class="form-control" id="password" placeholder="Saisir le mot de passe">
          </div>
          <div class="mb-3">
            <label for="confirmed_password" class="form-label">Confirmer le mot de passe</label>
            <input v-model="confirmed_password" type="password" class="form-control" id="confirmed_password" placeholder="Confirmer le nouveau mot de passe">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Nom d'utilisateur</label>
            <input v-model="name" type="text" class="form-control" id="name" placeholder="Saisir votre nom ">
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Date de naissance</label>
            <VueDatePicker v-model='birthday' type="date" :format="format" id="birthday" placeholder="Saisir votre nom "></VueDatePicker>
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Email</label>
            <input v-model="email" type="email" class="form-control" id="email" placeholder="Saisir votre email">
          </div>
<!--          <div class="mb-3">-->
<!--            <label for="password" class="form-label">Code de vérification</label>-->
<!--            <div class="Verification-Code">-->
<!--              <input v-model="verification" type="text" class="form-control" placeholder="Saisir le code de vérification">-->
<!--              <button class="btn btn-primary Verification-Code-btn" @click="getVerificationCode">-->
<!--                Obtenir le code de vérification-->
<!--              </button>-->
<!--            </div>-->

<!--          </div>-->

          <div class="error-message">{{ error_message }}</div>
          <button type="submit" class="btn btn-primary">S'enregistrer </button>
        </form>
      </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {ref} from "vue";
import $ from 'jquery';
import router from "@/router";

export default {
  name: "RegisterView",
  components: {
    ContentField,
    VueDatePicker
  },
  setup() {
    let username = ref('');
    let name = ref('');
    let password = ref('');
    let confirmed_password = ref('');
    let email = ref('');
    let error_message = ref('');
    let birthday = ref(new Date());

    const format = (birthday) => {
      const day = birthday.getDate();
      const month = birthday.getMonth() + 1;
      const year = birthday.getFullYear();

      return `${day}/${month}/${year}`;
    }

    const register = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/user/account/register/',
        type: 'post',
        data: {
          username: username.value,
          password: password.value,
          email: email.value,
          name: name.value,
          birthday: birthday.value.getTime(),
          confirmed_password: confirmed_password.value
        },
        success(resp) {
          if (resp.error_message === "success") {
            router.push({ name: 'login' });
          } else {
            error_message.value = resp.error_message;
          }
        },
      })
    }

    return {
      username,
      password,
      confirmed_password,
      name,
      email,
      error_message,
      birthday,

      register,
      format
    }
  }
}
</script>

<style scoped>
button {
  width: 100%;
}

div.error-message {
  color: red;
}

.Verification-Code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Verification-Code-btn {
  margin-left: 10px;
}
</style>