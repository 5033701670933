<template>
  <ContentField>

    <table class="table">
      <thead>
      <tr>
        <th scope="col">Mon historique</th>
        <th scope="col">Montant/Frais/Montant frais inclus/Recevoir/Date/Nom/Numéro de téléphone/Photo </th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="item in data" :key="item.id">
          <td v-if="item.type === 1">GABON/SENEGAL VERS CHINE</td>
          <td v-else-if="item.type === 4">PRÊT D'ARGENT</td>
          <td v-else-if="item.type === 2">CHINE VERS GABON/SENEGAL</td>
          <td v-else-if="item.type === 3">GABON VERS SENEGAL</td>
          <td v-else-if="item.type === 5">ENVOYER VIA PAYPAL</td>
          <td v-else-if="item.type === 6">RECEVOIR VIA PAYPAL</td>
          <td>{{ item.envoyer }} / {{ item.frais }} / {{item.inclus}} / {{item.recevoir}} / {{item.date}} / {{item.name}} / {{item.username}}/
            <el-button type="primary" @click="showDialog(item.image1)">Photo1 &nbsp;</el-button>
            <el-button type="primary" @click="showDialog(item.image2)">Photo2 &nbsp;</el-button>
            <el-button type="primary" @click="showDialog(item.image3)">Photo3</el-button>
<!--            <a :href="item.image1" target="_blank">Photo1 &nbsp;</a>-->
<!--            <a :href="item.image2" target="_blank">Photo2 &nbsp;</a>-->
<!--            <a :href="item.image3" target="_blank">Photo3</a>-->
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Mon historique</th>
        <th scope="col">Montant/Pourcentage/Montant à rembourser/Nom/Numéro de téléphone/Passport/Adresse/Date/Photo </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in data2" :key="item.id">
        <td>Prêt d'argent</td>
        <td>{{ item.montant }} / {{ item.rate }}% / {{item.rembourser}} / {{item.name}} / {{item.username}} / {{item.passport}} / {{item.address}} / {{item.date}}/
          <el-button type="primary" @click="showDialog(item.image1)">Photo1 &nbsp;</el-button>
          <el-button type="primary" @click="showDialog(item.image2)">Photo2 &nbsp;</el-button>
          <el-button type="primary" @click="showDialog(item.image3)">Photo3</el-button>
<!--          <a :href="item.image1" target="_blank">Photo1 &nbsp;</a>-->
<!--          <a :href="item.image2" target="_blank">Photo2 &nbsp;</a>-->
<!--          <a :href="item.image3" target="_blank">Photo3</a>-->
        </td>
      </tr>
      </tbody>
    </table>
  </ContentField>
  <el-dialog
      v-model="dialogVisible"
      title="photo"
      width="30%">
    <el-image :src="image_url"></el-image>
  </el-dialog>
</template>

<script>
import ContentField from "@/components/ContentField";
import {ref} from "vue";
import {useStore} from "vuex";
import $ from 'jquery';

export default {
  name: "MyRecordView",
  components: {
    ContentField
  },
  setup() {
    const store = useStore();

    let data = ref('');
    let data2 = ref('');

    let dialogVisible = ref(false);
    let image_url = ref('');

    let showDialog = (link) => {
      dialogVisible.value = true;
      image_url.value = link;
    }

    let get_record1 = () => {
      $.ajax({
        url: 'https://expresspaybn.com/api/record1/getlist/',
        type: 'get',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          data.value = resp;
        }
      })
      $.ajax({
        url: 'https://expresspaybn.com/api/record2/getlist/',
        type: 'get',
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          data2.value = resp;
        }
      })
    }
    get_record1();

    return {
      data,
      data2,

      dialogVisible,
      image_url,
      showDialog
    }
  }
}
</script>

<style scoped>

</style>