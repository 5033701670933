import {createStore} from "vuex";
import ModuleUser from "@/store/user";
import ModuleVar from "@/store/var"

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        user: ModuleUser,
        var: ModuleVar
    }
})
